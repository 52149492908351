import React from 'react'
import {
    Processing,
    PromptsList,
    PromptEditor,
    Review,
    Upload,
    PromptFlowList,
    PromptFlowEditor, ResultsList, ResultView
} from "./views";

const AppRoutes = [
    // {
    //     index: true,
    //     element: <Welcome />
    // },
    {
        index: true,
        // path: '/upload',
        element: <Upload />
    },
    {
        path: '/processing',
        element: <Processing />
    },
    {
        path: '/preview',
        element: <Review />
    },
    {
        path: '/prompts',
        element: <PromptsList />
    },
    {
        path: '/prompts/new',
        element: <PromptEditor />
    },
    {
        path: '/prompts/edit/:categoryId',
        element: <PromptEditor />
    },
    {
        path: '/promptflows',
        element: <PromptFlowList />
    },
    {
        path: '/promptflows/new',
        element: <PromptFlowEditor />
    },
    {
        path: '/promptflows/edit/:flowId',
        element: <PromptFlowEditor />
    },
    {
        path: '/proposals',
        element: <ResultsList />
    },
    {
        path: '/proposals/:proposalId',
        element: <ResultView />
    }
];

export default AppRoutes;
