import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent, Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {Add} from "@mui/icons-material";
import {FlowType, PromptFlow, PromptFlowStep} from "../../models/promptflow/PromptFlow";
import FlowStep from "./FlowStep";
import {PromptsRepository} from "../../repositories/PromptsRepository";
import {useAuth} from "react-oidc-context";
import {Prompt} from "../../models/prompt/Prompt";
import {CopyIcon, Save, TrashIcon} from "lucide-react";
import {PromptFlowRepository} from "../../repositories/PromptFlowRepository";
import {ObjectState} from "../../models/Shared";
import {DragDropContext, Draggable, Droppable, DropResult} from "@hello-pangea/dnd";
import LoadingIndicator from "../../components/LoadingIndicator";

const PromptFlowEditor = () => {
    const auth = useAuth();
    const { flowId } = useParams();
    const [ promptFlow, updateFlow ] = useState<PromptFlow | undefined>();
    const [ prompts, setPrompts ] = useState<Prompt[]>([]);
    const promptsRepository = new PromptsRepository(auth);
    const promptFlowsRepository = new PromptFlowRepository(auth);
    const [ snackbarOpen, setSnackbarOpen ] = useState<boolean>(false);
    const [ snackbarMessage, setSnackbarMessage ] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        setIsLoading(true);
        getActivePrompts().catch(e => console.error(e));
        
        if (flowId === undefined || flowId === '') {
            // new flow
            console.log("new flow");
            updateFlow(new PromptFlow());
        } else {
            // edit flow
            console.log("edit flow", flowId);
            getFlow(+flowId).catch(e => console.error(e));
        }
        setIsLoading(false);
    }, []);

    const getActivePrompts = async () => {
        let activePrompts = await promptsRepository.getActivePrompts();
        
        if (activePrompts) {
            activePrompts = [ { promptId: -1, categoryName: "(geen selectie/eigen tekst)", categoryId: "-1" } as Prompt, ...activePrompts ];
            setPrompts(activePrompts);
        }
    }
    
    const getFlow = async (id: number) => {
        let flow = await promptFlowsRepository.get(id)
        if (flow !== undefined) {
            updateFlow(flow);
        }
    }

    const addItem = () => {
        if (promptFlow === undefined) {
            return
        }
        
        let item = new PromptFlowStep()
        item.index = promptFlow.promptFlowSteps.length + 1;
        item.promptCategoryId = "-1";
        item.id = item.index;
        item.state = ObjectState.New;
        updateFlow({ ...promptFlow, promptFlowSteps: [...promptFlow.promptFlowSteps, item] });
    };
    
    const updateItem = (item: PromptFlowStep) => {
        if (promptFlow === undefined) {
            return
        }
        
        const index = promptFlow.promptFlowSteps.findIndex(i => i.id == item.id);
        let items = promptFlow.promptFlowSteps;
        items[index] = item;

        updateFlow({ ...promptFlow, promptFlowSteps: items });
    }

    const omUpdateName = (e: any) => {
        if (promptFlow === undefined) {
            return
        }

        updateFlow({ ...promptFlow, name: e.target.value });
    };
    
    const onUpdateDescription = (e: any) => {
        if (promptFlow === undefined) {
            return
        }

        updateFlow({...promptFlow, description: e.target.value});
    };

    const onSelectFlowType = (e: SelectChangeEvent) => {
        if (promptFlow === undefined) {
            return
        }

        updateFlow({...promptFlow, flowType: e.target.value as FlowType});
    };

    const handleSaveClick = async () => {
        if (promptFlow === undefined) {
            return
        }
        
        if (promptFlow.promptFlowSteps.length === 0) {
            alert("Geen stappen gedefinïeerd")
            return
        }
        
        let result: PromptFlow | undefined;
        if (promptFlow.id > 0) {
            result = await promptFlowsRepository.update(promptFlow);
            updateFlow(result);
        } else {
            result = await promptFlowsRepository.add(promptFlow);
        }
        if (result !== undefined) {
            updateFlow(result);
            setSnackbarMessage("Succesvol opgeslagen...")
            setSnackbarOpen(true);
        }
    };

    const updateIsActive = (e:  any) => {
        if (promptFlow === undefined) {
            return
        }
        updateFlow({...promptFlow, isActive: e.target.checked });
    };

    const onDragEnd = (result: DropResult) => {
        if (promptFlow?.promptFlowSteps === undefined || !result.destination) {
            return
        }
        
        let items = promptFlow.promptFlowSteps
        let source = result.source.index;
        let dest = result.destination.index;
        // console.log("onDragEnd", source, dest, items);
        if (source > dest) {
            // moved up
            for (let i = dest; i < items.length; i++) {
                items[i].index = items[i].index + 1
            }
            items[result.source.index].index = dest + 1;
        } else {
            // moved down
            for (let i = source; i <= dest; i++) {
                items[i].index = items[i].index - 1
            }
            items[result.source.index].index = dest + 1;
        }
        updateItems(items)
    };

    const deleteStep = (item: PromptFlowStep) => {
        if (promptFlow?.promptFlowSteps === undefined) {
            return
        }
        let index = promptFlow.promptFlowSteps.findIndex((i) => i.index === item.index);
        if (index < 0 ) {
            return;
        }
        let items = promptFlow.promptFlowSteps
        items.splice(index, 1);
        updateItems(items);
    };
    
    const updateItems = (items: PromptFlowStep[]) => {
        if (promptFlow?.promptFlowSteps === undefined) {
            return
        }
        
        // make sure there are nog gaps
        items.sort((a, b) =>{ return a.index - b.index });
        for (let i = 0; i < items.length; i++) {
            items[i].index = i + 1;
        }
        
        // console.log(items)
        updateFlow({ ...promptFlow, promptFlowSteps: items });
    }

    const handleCopyClick = async () => {
        if (promptFlow === undefined || !window.confirm("Zeker weten?\nDeze flow wordt gekopieerd en meteen als nieuw opgeslagen.")) {
            return
        }
        // copy flow and reset some values
        let flow = {...promptFlow, id: 0, name: `Kopie van ${promptFlow.name}`, description: "", isActive: false };
        for (let s of flow.promptFlowSteps) {
            s.state = ObjectState.New;
            s.id = 0;
        }
        // post as new flow
        let result = await promptFlowsRepository.add(flow);
        if (result !== undefined) {
            // navigate and reload to reset screen
            window.scrollTo(0, 0);
            navigate({
                pathname: `/promptflows/edit/${result.id}`,
            });
            window.location.reload();
        }
    };
    
    const handleDeleteClick = async () => {
        if (promptFlow === undefined || !window.confirm("Weet je zekere dat je deze flow wilt verwijderen?\nDeze actie kan niet ongedaan worden gemaakt!")) {
            return
        }
        
        let result = await promptFlowsRepository.delete(promptFlow.id);
        if (result === true) {
            // navigate and reload to reset screen
            navigate({
                pathname: `/promptflows/`,
            });
            window.location.reload();
        }
    };
    
    return (
        <Container maxWidth="md" sx={{mt: 4, mb: 4}}>
            <Paper elevation={3} sx={{p: 3, bgcolor: "background.paper"}}>
                <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
                    {/*<History size={24} color={theme.palette.secondary.main} />*/}
                    <Typography variant="h5" sx={{ml: 1}} color="secondary">
                        {flowId === undefined ? 'Nieuwe flow' : `${flowId} bewerken`}
                    </Typography>
                </Box>
                <InputLabel id="flowtype-select-label" color={"secondary"}>Flow type</InputLabel>
                <Select
                    sx={{width: "45%", mb: "25px"}}
                    labelId="flowtype-select-label"
                    value={`${promptFlow?.flowType ?? FlowType.Proposal}`}
                    label="Flowtype"
                    onChange={onSelectFlowType}>
                    <MenuItem value={FlowType.Proposal}>Voorstel</MenuItem>
                    <MenuItem value={FlowType.Feedback}>Feedback</MenuItem>
                </Select>
                <TextField
                    fullWidth
                    rows={4}
                    variant="outlined"
                    value={promptFlow?.name ?? ""}
                    onChange={omUpdateName}
                    placeholder="Geef naam van de flow..."
                    sx={{mb: 2}}
                />
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={promptFlow?.description ?? ""}
                    onChange={onUpdateDescription}
                    placeholder="Geef een omschrijving van de flow..."
                    sx={{mb: 2}}
                />
                <FormControlLabel control={ <Checkbox onChange={updateIsActive} checked={promptFlow?.isActive ?? false} /> } label={"Actief"} />
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <List {...provided.droppableProps} ref={provided.innerRef}>
                                {promptFlow?.promptFlowSteps.map((item, index   ) => (
                                    <Draggable key={item.id} draggableId={"" + item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <ListItem sx={{p: 0}}
                                                      key={item.index} ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}>
                                                <FlowStep item={item} prompts={prompts}
                                                          onUpdate={updateItem}
                                                          onDelete={deleteStep}/>
                                                <Divider sx={{mb: 2}}/>
                                            </ListItem>
                                            )}
                                    </Draggable>    
                                ))}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button
                    variant="contained"
                    startIcon={<Add/>}
                    onClick={addItem}
                    sx={{mb: 3}}
                >
                    Stap toevoegen
                </Button>
                <Button
                    variant="contained"
                    startIcon={<CopyIcon />}
                    onClick={handleCopyClick}
                    sx={{mb: 3, ml: 3}}
                >
                    Kopïeer Flow
                </Button>
                <Button
                    variant="contained"
                    startIcon={<Save />}
                    onClick={handleSaveClick}
                    sx={{mb: 3, ml: 3}}
                >
                    Opslaan
                </Button>
                <Button
                    variant="contained"
                    startIcon={<TrashIcon />}
                    onClick={handleDeleteClick}
                    sx={{mb: 3, ml: 3, bgcolor: "error.main"}}
                >
                    Verwijderen
                </Button>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    autoHideDuration={3000}
                    message={snackbarMessage}
                />
                <LoadingIndicator show={isLoading}/>
            </Paper>
        </Container>
    );
}
export default PromptFlowEditor;