import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Container,
    Paper,
    Typography
} from "@mui/material";
import {Download} from "@mui/icons-material";
import {DocxGenerator, HtmlGenerator} from "../Utils/MarkdownConvert";

interface IDocumentViewerProps {
    markdown: string;
}

const DocumentViewer = ( { markdown }: IDocumentViewerProps ) => {
    const [text, setText] = useState("");
    
    useEffect(() => {
        parseMarkdown().catch();
    }, [markdown]);
    
    const parseMarkdown = async () => {
        const html = await HtmlGenerator.convert(markdown);
        
        setText(html);
    }

    const handleDownload = async () => {
        await DocxGenerator.convert(markdown);
    };

    return (
        <>
            <Paper elevation={3} sx={{p: 3, bgcolor: "background.paper"}}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                    }}
                >
                    <img
                        src="/Logo.svg"
                        alt="Logo"
                        style={{height: "50px"}}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Download/>}
                        onClick={handleDownload}
                    >
                        Download als Word
                    </Button>
                </Box>

                <Typography variant="h6" gutterBottom>
                    Voorgesteld besluit
                </Typography>
                <Typography variant="body1" paragraph>
                </Typography>
                <div dangerouslySetInnerHTML={{__html: text}}/>
            </Paper>
        </>
    );
}

export default DocumentViewer;