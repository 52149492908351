import React, {useEffect, useState} from "react";
import {useRequestState} from "../../hooks/RequestState";
import DocumentViewer from "../../components/DocumentViewer";
import {Container} from "@mui/material";

const Review = () => {
    const {requestState, updateRequestState} = useRequestState();
    const [text, setText] = useState("");

    useEffect(() => {
        const markdown = requestState.result ?? "# No data";
        setText(markdown); 
        
    }, [requestState]);
    
    return (
        <Container maxWidth="md" sx={{mt: 4, mb: 4}}>
            <DocumentViewer markdown={text}/>
        </Container>
    );
};


export default Review;