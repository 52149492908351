export class Configuration {
    private static _baseApiUrl = process.env.REACT_APP_API_URL === undefined 
        ? "https://api-dev.rakevoorstellen.nl" : process.env.REACT_APP_API_URL;
    private static _authBaseUrl = process.env.REACT_APP_AUTHORITY_URL === undefined 
        ? "https://auth-dev.rakevoorstellen.nl" : process.env.REACT_APP_AUTHORITY_URL;
    private static _realmName = "RakeVoorstellen";

    public static get baseApiUrl(): string {
        return this._baseApiUrl;
    }

    public static get proposalGeneratorApiBaseUrl(): string {
        return `${this.baseApiUrl}/api/v0/ProposalGenerate`
    }

    public static get promptsApiBaseUrl(): string {
        return `${this.baseApiUrl}/api/v0/prompts`
    }
    
    public static get promptFlowsApiBaseUrl(): string {
        return `${this.baseApiUrl}/api/v0/promptflows`
    }
    
    public static get proposalsApiBaseUrl(): string {
        return `${this.baseApiUrl}/api/v0/proposalResults`
    }

    public static get authorityUrl(): string {
        return this._authBaseUrl + `/realms/${this._realmName}/`;
    }

    public static get accountyUrl(): string {
        return this._authBaseUrl + `/realms/${this._realmName}/account?referrer=rake-voorstellen`;
    }
}