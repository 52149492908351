import React, {ReactNode, useEffect, useState} from 'react';
import {AuthProvider, useAuth, hasAuthParams} from "react-oidc-context";
import {Navigate, useNavigate} from "react-router-dom";

interface Props {
    children: React.ReactNode
}

const LoginHelper = ({ children } : Props ) => {
    const auth = useAuth();

    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    const redirectPath = localStorage.getItem("redirectPath") || "";
    const [currentUrl, setCurrentUrl] = useState<string>(redirectPath);
    const navigate = useNavigate();

    // automatically sign-in
    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            const path = window.location.pathname + window.location.search;
            if (path !== "/") {
                console.log("signin", path);
                updateRedirectPath(path);
            }
                
            auth.signinRedirect().catch(console.error);
            setHasTriedSignin(true);
        }
        
        if (auth.isAuthenticated && currentUrl !== "") { //} && window.location.pathname !== currentUrl) {
            // navigate and reload to reset screen
            navigate({
                pathname: currentUrl,
            });
            // clear path
            updateRedirectPath("");
            // and force reload
            window.location.reload();
        }
    }, [auth, hasTriedSignin]);
    
    const updateRedirectPath = (path: string) => {
        localStorage.setItem('redirectPath', path)
        // setCurrentUrl(path);
    }

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }
    
    // console.log(auth);

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    
    if (auth.isAuthenticated) {
        return (
            <>{children}</>
        );
    }

    return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
}

export interface IAuthProvider {
    authConfig: any;
    children: ReactNode;
}

export function Authenticator({ authConfig, children }:IAuthProvider) : JSX.Element {
    return (
        <AuthProvider {...authConfig}>
            <LoginHelper>{children}</LoginHelper>
        </AuthProvider>
    );
}
