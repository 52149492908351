import React, {useEffect, useState} from "react";
import DocumentViewer from "../../components/DocumentViewer";
import {useAuth} from "react-oidc-context";
import {ProposalsRepository} from "../../repositories/ProposalsRepository";
import {Proposal} from "../../models/proposal/Proposal";
import {useParams} from "react-router-dom";
import {Button, ButtonGroup, Container} from "@mui/material";

const ResultView = () => {
    const auth = useAuth();
    const repo = new ProposalsRepository(auth);
    const {proposalId} = useParams();
    const [proposal, setProposal] = useState<Proposal | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [text, setText] = useState("");

    useEffect(() => {
        
        if (proposalId === undefined || proposalId === '') return;
        
        setIsLoading(true);
        getProposal(proposalId).catch(console.error);
    }, []);
    
    const getProposal = async (id: string) => {
        const proposal = await repo.getProposal(id);
        setIsLoading(false);
        if (proposal !== undefined) {
            setProposal(proposal);
            setText(proposal?.proposalResultItems[0].result ?? "");
        }
    }
    
    const selectVariant = (index: number) => {
        setText(proposal?.proposalResultItems[index].result ?? "");
    }
    
    return (
        <Container maxWidth="md" sx={{mt: 4, mb: 4}}>
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                { proposal?.proposalResultItems.map((item, index) => (
                    <Button key={item.id} onClick={() => selectVariant(index)}>Variant {index + 1}</Button>
                ))}
            </ButtonGroup>
            <DocumentViewer markdown={text} />
        </Container>
    );
}

export default ResultView;
