import {Configuration} from "../Configuration";
import {AuthContextProps} from "react-oidc-context";
import {Prompt} from "../models/prompt/Prompt";
import {Proposal} from "../models/proposal/Proposal";

export class ProposalsRepository {
    baseApiUrl = Configuration.proposalsApiBaseUrl;
    private auth: AuthContextProps;

    constructor(auth: AuthContextProps) {
        this.auth = auth;
    }

    async getProposals (): Promise<Proposal[] | undefined>  {
        const response = await fetch(`${this.baseApiUrl}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
        });
        if (response.status !== 200) {
            alert("Error retrieving proposals: " + response.status);
            return;
        }

        return await response.json() as Proposal[];
    }
    
    async getProposal (id: string): Promise<Proposal | undefined>  {
        const response = await fetch(`${this.baseApiUrl}/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
        });
        if (response.status !== 200) {
            alert("Error retrieving proposal: " + response.status);
            return;
        }

        return await response.json() as Proposal;
    }
}