import {Configuration} from "../Configuration";
import {AuthContextProps} from "react-oidc-context";
import {PromptFlow} from "../models/promptflow/PromptFlow";
import {Prompt} from "../models/prompt/Prompt";

export class PromptFlowRepository {
    baseApiUrl = Configuration.promptFlowsApiBaseUrl;
    private auth: AuthContextProps;

    constructor(auth: AuthContextProps) {
        this.auth = auth;
    }
    
    async allFlows(): Promise<PromptFlow[] | undefined> {
        const response = await fetch(`${this.baseApiUrl}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
        });

        if (response.status !== 200) {
            alert("Error retrieving prompts: " + response.status);
            return;
        }

        return await response.json() as PromptFlow[];
    }


    async flowsForCategory(categoryId: string): Promise<PromptFlow[] | undefined> {
        const response = await fetch(`${this.baseApiUrl}/forCategory/${categoryId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
        });

        if (response.status !== 200) {
            alert("Error retrieving prompts: " + response.status);
            return;
        }

        return await response.json() as PromptFlow[];
    }
    
    async activeFlows(): Promise<PromptFlow[] | undefined> {
        const response = await fetch(`${this.baseApiUrl}/active/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
        });

        if (response.status !== 200) {
            alert("Error retrieving prompts: " + response.status);
            return;
        }

        return await response.json() as PromptFlow[];
    }
    
    async get(id: number): Promise<PromptFlow | undefined> {
        const response = await fetch(`${this.baseApiUrl}/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
        });

        if (response.status !== 200) {
            alert("Error retrieving prompts: " + response.status);
            return;
        }

        return await response.json() as PromptFlow;
    }
    
    async add(flow: PromptFlow): Promise<PromptFlow | undefined> {
        flow.id  = -1;
        const response = await fetch(`${this.baseApiUrl}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
            body: JSON.stringify(flow),
        });

        if (response.status !== 200) {
            alert("Error saving flow: " + response.status);
            return;
        }

        return  await response.json() as PromptFlow;
    }
    
    async update(flow: PromptFlow): Promise<PromptFlow | undefined> {
        const response = await fetch(`${this.baseApiUrl}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
            body: JSON.stringify(flow),
        });

        if (response.status !== 200) {
            alert("Error saving flow: " + response.status);
            return;
        }

        return  await response.json() as PromptFlow;
    }
    
    async delete(id: number): Promise<boolean | undefined> {
        const response = await fetch(`${this.baseApiUrl}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.auth.user?.access_token}`,
            },
        });

        if (response.status !== 200) {
            alert("Error deleting prompt: " + response.status);
            return false;
        }

        const result =  await response.json() as boolean;
        if (!result) {
            alert("Verwijderen retourneerde false?")
        }
        
        return result;
    }
}